@import url("//hello.myfonts.net/count/3c4c47");
@import url("//hello.myfonts.net/count/3c4c46");

@font-face {
  font-family: "FrutigerNextLTW1G";
  src: local("FrutigerNextLTW1G"), url("./assets/fonts/FrutigerNextLTW1G-Regular/font.woff2") format("woff2"),
    url("./assets/fonts/FrutigerNextLTW1G-Regular/font.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "FrutigerNextLTW1G";
  src: local("FrutigerNextLTW1G"), url("./assets/fonts/FrutigerNextLTW1G-Bold/font.woff2") format("woff2"),
    url("./assets/fonts/FrutigerNextLTW1G-Bold/font.woff") format("woff");
  font-weight: bold;
}

.App {
  font-family: "FrutigerNextLTW1G", sans-serif !important;
  background-color: #ffffff;
  height: 100vh;

  @media (min-width: 992px) {
    max-width: 70%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  h1 {
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
  }

  h2 {
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.5em;
  }

  .warning-text {
    color: #eb0029;
    font-size: 0.8em;
    margin-top: 5px;
  }

  .consumption-data,
  .object-data {
    background-color: #fff;
    padding: 20px 0 10px 0;
    border-radius: 5px;

    label {
      font-weight: bold;
      color: #666;
    }

    h3 {
      font-weight: bold;
      margin-top: 0;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-size: 1.2em;
    }
  }

  input.form-control {
    border-radius: 0;
    border: 2px solid lightgray;
    height: 24px;
    padding: 0.25rem 0.5rem;

    &.input-invalid {
      border-color: #eb0029;
    }
  }

  .object-data {
    >div {
      >div {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        >div {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          flex-direction: row;
          position: relative;

          @media (max-width: 768px) {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
          }

          >.form-label {
            flex: 0 0 215px;

            @media (max-width: 768px) {
              flex: 0;
            }
          }

          >.radio-box {
            >.form-check {
              @media (max-width: 768px) {
                display: flex;
                flex-direction: row-reverse;
                padding-left: 0;
                justify-content: flex-end;

                input {
                  float: right;
                  margin-left: 10px;
                }
              }

              .form-check-label {
                position: absolute;
                left: 0;

                @media (max-width: 768px) {
                  position: relative;
                  left: auto;
                }
              }
            }
          }

          label.form-label {
            margin-bottom: 0;
          }

          input.form-control {
            min-width: 100px;
            max-width: 100px;

            @media (max-width: 768px) {
              max-width: 70%;
              width: auto;
            }
          }
        }

        >div.mb-1 {
          >div {
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .form-label {
    .mand-icon {
      position: relative;
      color: #333;
      z-index: 10;
      display: inline-block;
      margin-left: 0;
    }
  }

  .consumption-data {
    .info-text {
      display: flex;
      align-items: center;
    }

    >div {
      >div {
        >div {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;

          >.form-label {
            flex: 1 0 100%;
          }
        }
      }
    }

    .form-label {
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      &.or-label {
        margin-top: -0.5rem;
      }
    }

    .input-wrapper {
      position: relative;
      display: inline-block;
      max-width: 70%;

      @media (min-width: 576px) {
        min-width: 100px;
        max-width: 150px;
        margin-right: 23px;
      }

      &.multi-unit {
        margin-bottom: 8px !important;

        @media (min-width: 576px) {
          margin-right: 10px;
          margin-bottom: 0.35rem !important;
        }

        .custom-input {
          @media (min-width: 576px) {
            margin-top: -5px;
          }
        }
      }
    }
  }

  .form-control,
  .unit-text {
    display: inline-block;
    vertical-align: middle;
  }

  .form-group {
    @media (max-width: 767px) {
      .form-control {
        width: 60%;
      }
    }

    @media (min-width: 768px) {
      .form-control {
        width: 40%;
      }
    }
  }

  .custom-input {
    width: 100%;

    @media (min-width: 576px) {
      margin-top: -4px;
    }
  }

  .custom-unit-select {
    width: auto;
    display: inline-block;
    cursor: pointer;
    border-radius: 0;
    border: 2px solid lightgray;
    position: relative;
    appearance: auto;
    height: 24px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;

    @media (min-width: 768px) {
      margin-top: 1px;
    }
  }

  .input-wrapper,
  .custom-unit-select,
  .unit-text {
    margin-bottom: 0.35rem !important;
  }

  .unit-text {
    display: block;

    @media (min-width: 576px) {
      display: inline-block;
    }
  }

  select.form-control:not([size]):not([multiple]) {
    width: auto;
    display: block;

    @media (min-width: 576px) {
      display: inline;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .partial-consumption {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    display: none;
    margin-bottom: 10px;

    &.show {
      display: block;
    }

    .partial-consumption-textbox {
      font-size: 16px;
      margin-bottom: 1em;
      font-weight: normal;

      @media (min-width: 768px) {
        max-width: 70%;
      }
    }

    .partial-consumption-box {
      .value-display {
        display: flex;
        align-items: center;
        margin-top: 2px;
        flex-wrap: wrap;

        @media (min-width: 600px) {
          flex-wrap: nowrap;
        }

        input {
          margin-right: 10px;
          border: none;
          text-align: right;
          background-color: #ffffff;
          border: 2px solid lightgray;
          pointer-events: none;
          outline: none;
          width: 100px;
          flex: 0 0 100px;
          text-align: center;
          font-size: 16px;
          height: 24px;
        }

        span {
          display: inline-block;
          font-weight: 500;
          font-size: 0.7em;

          &:first-child {
            font-weight: bold;
            flex: 1 0 100%;

            @media (min-width: 440px) {
              flex: 0 0 85px;
            }
          }
        }

        &.total {
          justify-content: center;

          @media (min-width: 600px) {
            justify-content: flex-start;
          }

          >span {
            &:not(.nt-ready) {
              margin-right: 24px;
            }
          }
        }
      }

      &.large {
        .value-display {
          span {
            &:first-child {
              @media (min-width: 440px) {
                flex: 0 0 160px;
              }
            }
          }
        }
      }
    }
  }

  .total-consumption {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;

    .total-consumption-textbox {
      font-size: 16px;
      margin-bottom: 1.75em;

      @media (min-width: 768px) {
        max-width: 70%;
      }
    }
  }


  .consumption-box {
    border-left: 15px solid;
    padding: 20px 20px 20px 5px;

    @media (min-width: 768px) {
      border-left: 20px solid;
      padding: 20px 25px 20px 15px;
    }

    margin-bottom: 20px;
    background-color: rgba(#000000, 0.08);
    transition: all 0.3s ease-in-out;

    &.green {
      border-left-color: #047b00;
    }

    &.yellow {
      border-left-color: #ec6607;
    }

    &.orange {
      border-left-color: #eb0029;
    }

    &.red {
      border-left-color: #eb0029;
    }

    h3 {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .value-display {
      display: flex;
      align-items: center;
      margin-top: 16px;
      flex-wrap: wrap;

      @media (min-width: 600px) {
        flex-wrap: nowrap;
      }

      input {
        margin-right: 10px;
        border: none;
        text-align: right;
        background-color: #ffffff;
        border: 2px solid lightgray;
        pointer-events: none;
        outline: none;
        width: 100px;
        flex: 0 0 100px;
        text-align: center;
      }

      span {
        display: inline-block;
        font-weight: 500;
        font-size: 0.8em;

        &:first-child {
          font-weight: bold;
          flex: 1 0 100%;

          @media (min-width: 440px) {
            flex: 0 0 180px;
          }
        }
      }

      &.total {
        justify-content: center;

        @media (min-width: 600px) {
          justify-content: flex-start;
        }

        >span {
          &:not(.nt-ready) {
            margin-right: 24px;
          }
        }
      }
    }

    p {
      font-size: 0.65em;
      line-height: 1.1em;
      font-weight: 400;
      color: #333;
    }
  }

  .mand-icon {
    margin-left: 5px;
  }

  .input-invalid {
    border: 1px solid #eb0029;
  }

  .warning-text {
    color: #eb0029;
    font-size: 0.8em;
    margin-top: 5px;
  }

  .area-unit {
    display: inline-block;
    margin-left: 5px;
  }

  p.text-small {
    font-size: 13px !important;
    line-height: 15px;
  }
}

.custom-tooltip {
  &.show {
    opacity: 1 !important;
    visibility: visible;
  }

  >.tooltip-arrow {
    border-color: white;
    border-style: solid;

    &:before {
      border-color: transparent !important;
    }
  }

  &.bs-tooltip-end {
    .tooltip-arrow {
      border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
      border-right-color: #cfcfcf !important;

      &:before {
        right: -7px;
        border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
        border-right-color: white !important;
        top: -6px;
      }
    }
  }

  &.bs-tooltip-bottom {
    .tooltip-arrow {
      border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
      border-bottom-color: #cfcfcf !important;

      &:before {
        bottom: -7px;
        border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
        border-bottom-color: white !important;
        left: -5px;
      }
    }
  }

  .tooltip-inner {
    background-color: white;
    color: #333;
    width: auto;
    padding: 0;
    border: 1px solid #cfcfcf;

    @media (min-width: 768px) {
      max-width: 500px;
    }

    .info-body {
      padding: 10px;
      font-weight: normal;
      font-size: 0.9em;
      text-align: left;
    }
  }
}

.skeleton-box {
  display: inline-block;
  height: 200px;
  position: relative;
  overflow: hidden;
  background-color: rgba(#000000, 0.08);
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#ffffff, 0) 0,
        rgba(#ffffff, 0.2) 20%,
        rgba(#ffffff, 0.5) 60%,
        rgba(#ffffff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.reset-button {
  display: inline-block;
  font-size: 0.85em;
  font-weight: bold;
  color: #666;
  cursor: pointer;
  text-decoration: underline;
}

.info-icon {
  position: relative;
  cursor: pointer;
  color: #333;
  z-index: 10;
  display: inline-block;
  margin-left: 5px;
  border-radius: 50%;
  border: 1px solid #333;
  height: 16px;
  width: 16px;
  flex: 0 0 16px;

  &:after {
    content: 'i';
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333;
  }
}
